$header-height: 4rem;
$borderColor: rgba(34,36,38,.15);

.main-container {
  
  #header {
    margin-bottom: 0px;
    height: $header-height;
  }
  
  .flex-container {
    margin-top: $header-height;
    height: calc(100vh - #{$header-height});
    display: flex;
    justify-content: space-between;
    
    .main {
      flex: 1 1 0;
      overflow: auto; 
    }

    .sidebar {
      flex: 0 0 15%;
      height: calc(100vh - #{$header-height});
      border-left: 2px solid $borderColor;
      overflow: auto; 


      .menu{
        .item {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .icons-container {
            display: flex;
          }
        }
      }
    }
  }
}
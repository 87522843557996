.upload-progress {
  position: fixed;
  bottom: 0;
  width: fit-content;
  left: 0;
  right: 0;
  margin: 0 auto;

  .progress-item {
    display: flex;
    flex-direction: row;
    align-items: center;

    .progress {
      width: 20vw;
      margin-bottom: 0;
      margin-right: 10px;
    }

  }
}
.documents-table-subheader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 4px;
  padding-bottom: 8px;
  border-bottom: 1px solid whitesmoke;

  .checkboxes {
    margin-left: auto;
    margin-right: 20px;

    .checkbox {
      margin-left: 20px;
    }
  }
}